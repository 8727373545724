import $ from 'jquery';
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';
import '@fortawesome/fontawesome-free/js/brands';
import './bootstrap/bootstrap.min.css';
import './bootstrap/bootstrap-theme.min.css';
import './bootstrap/bootstrap.min';
import './sass/main.scss';
//import 'bootstrap';

global.jQuery = $;
global.$ = $;

$(document).on('mouseover', '.loginname', function () {
    $('.logout').fadeIn(250);
});
$(document).on('mouseleave', '.loginname', function () {
    $('.logout').fadeOut(250);
});
$(document).on('click', '#logout', function () {
    window.location = '/logout';
});
$(document).on('click', '#mask-icon', function () {
    if ($('.mask-status').text() != '') {
        $.get('/masq/clear', function () {
            $('.mask-status').text('');
            window.location.reload();
        });
    } else {
        if ($('.mask-select').css('width') == '200px') {
            //$('.mask-select').hide('slide', {direction: 'right'}, 1000);
            $('.mask-select').animate({ width: '-=200' }).hide();
        } else {
            $.get('/masq/users', function (data) {
                $('.mask-select').html(data);
                //$('.mask-select').show('slide', {direction: 'left'}, 1000);
                $('.mask-select').animate({ width: '+=200' }).show();
                $('#masquerade').on('change', function () {
                    $.get('/masq/set/' + $(this).find('option:selected').attr('id'), function (data) {
                        if (data !== 'error') {
                            $('.mask-status').text(data);
                            window.location.reload();
                        } else {
                            $('.mask-status').text('error');
                        }
                        $('.mask-select').animate({ width: '-=200' }).hide();
                    });
                });
            });
        }
    }
});
